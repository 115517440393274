<template>
    <div>
        <CRow>
            <CCol sm="12" md="12">
                <CCard>
                    <CCardHeader>

                        <CRow>
                            <CCol md="7">
                                <h4>WeChat MG Account Balance</h4>
                            </CCol>
                            <CCol md="5">
                                <CInput label="Search" v-model="filter" placeholder="Type to Search" horizontal />
                            </CCol>
                        </CRow>
                    </CCardHeader>

                    <CCardBody>
                        <CForm>
                            <CRow>
                                <CCol col="12" class="text-left">
                                    <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
                                </CCol>
                            </CRow>
                            <div>
                                <b-spinner variant="success" label="Spinning" v-if="spinner"></b-spinner>
                            </div>
                            <b-row>
                                <b-col sm="2">
                                    <div class="form-group">
                                        <label for="input-small">Currency :</label>
                                        <b-form-select @change="onChangeCurrency" v-model="currency" :options="optCurrency">
                                        </b-form-select>
                                    </div>
                                </b-col>
                                <b-col sm="4">
                                    <div class="form-group">
                                        <label for="input-small">Balance :</label>
                                        <b-form-input id="input-small" v-model="amount" :readonly="true">
                                        </b-form-input>
                                    </div>
                                </b-col>
                                <b-col sm="6">
                                    <div class="form-group">
                                        <label for="input-small">CNY Balance :</label>
                                        <b-form-input id="input-small" v-model="cny_amount" :readonly="true">
                                        </b-form-input>
                                    </div>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col sm="6">
                                    <div class="form-group">
                                        <label for="input-small">FX Rates :</label>&nbsp;
                                        <span id="timer" style="font-weight: bold; font-size: 15px;"></span> seconds remaining..                                        
                                        <b-form-input id="input-small" v-model="rates" :readonly="true"></b-form-input>
                                        <label style="color:red; font-size : 13px;">{{ err_msg }}</label>
                                    </div>
                                </b-col>
                                <b-col sm="6">
                                    <div class="form-group">
                                        <label for="input-small">Sell Amount :</label>
                                        <b-input-group>
                                            <b-form-input id="input-small" min=0 step="any" v-model="sell_amount"></b-form-input>
                                            <b-input-group-append>
                                                <b-button v-on:click="actionSubmit()" variant="outline-primary">Submit
                                                </b-button>
                                            </b-input-group-append>
                                        </b-input-group>
                                    </div>
                                </b-col>
                            </b-row>
                        </CForm>

                        <div class="table-responsive">
                            <div>
                                <b-table sticky-header striped hover :items="items" :fields="fields"
                                    :current-page="currentPage" :per-page="perPage" :filter="filter"
                                    sort-by="createDate" :sort-desc="true">

                                    <template v-slot:cell(createDate)="{ item }">
                                        {{ item.createDate | dateWithTime }}
                                    </template>

                                    <template v-slot:cell(sellAmount)="{ item }">
                                        {{ item.sellAmount | formatNum }}
                                    </template>

                                    <template v-slot:cell(fxRate)="{ item }">
                                        {{ item.fxRate | formatRate }}
                                    </template>

                                    <template v-slot:cell(transactionStatus)="{ item }">
                                        {{ item.transactionStatus | setLabel }}
                                    </template>

                                    <template v-slot:cell(actions)="{ item }">
                                        <span v-if="item.approvalStatus !== 'REJECTED'">
                                        <b-dropdown variant="info" text="Actions" size="md"> 
                                            <span v-if="item.approvalStatus === 'APPROVED'">                                                
                                                <router-link to="#" exact v-on:click.native="showStatus(item.memberTradeNo)"
                                                    tag="b-dropdown-item">Check Status </router-link>                                                
                                            </span>
                                            <span v-else-if="item.approvalStatus === 'PENDING'" >
                                                <span v-if="action == true">
                                                <router-link to="#" exact
                                                    v-on:click.native="actionApprove(item.id,item.sellCurrency,item.sellAmount)"
                                                    tag="b-dropdown-item"> Approve </router-link>
                                                <router-link to="#" exact v-on:click.native="actionReject(item.id)"
                                                    tag="b-dropdown-item"> Reject </router-link>
                                                </span>
                                                <span v-else>
                                                    <router-link to="#" tag="b-dropdown-item"> has no action role </router-link>
                                                </span>
                                            </span>                                            
                                        </b-dropdown>
                                        </span>
                                    </template>

                                </b-table>
                            </div>
                        </div>

                        <div>
                            <b-row>
                                <b-col sm="3">
                                    <b-pagination size="md" :total-rows="this.items.length" :per-page="perPage"
                                        v-model="currentPage" />
                                </b-col>
                                <b-col sm="9">
                                    <b-form-select
                                        :options="[{ text: 25, value: 25 }, { text: 50, value: 50 }, { text: 100, value: 100 }]"
                                        v-model="perPage" style="width:100px;">
                                    </b-form-select>
                                </b-col>
                            </b-row>
                        </div>

                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
    </div>
</template>

<script>
import transactService from '@/api/TransactService.js';
import { ToggleButton } from 'vue-js-toggle-button';

export default {
    name: 'account_balance',
    components: {
        'toggle-button': ToggleButton
    },
    data() {
        return {
            spinner: false,
            timeleft: 15,
            params: {},
            status: { 1: "ACCEPTED", 2: "COMPLETED", 3: "FAILED", 4:'REVERSED'},
            seen: false,
            buy_amount: 0,
            sell_amount: 0,
            cny_amount: 0,           
            amount:0,
            usd_balance: 0,           
            sgd_balance: 0,            
            action: false,
            rates: 0,
            msg: '',
            err_msg:'',
            color: '',
            filter: null,
            items: [],
            fields: [
                { key: 'createDate', label: 'Date Time', sortable: true },
                { key: 'memberTradeNo', label: 'Trade Num.', sortable: true },
                { key: 'buyCurrency', label: 'Buy Currency', sortable: true },
                { key: 'sellCurrency', label: 'Sell Currency', sortable: true },
                { key: 'sellAmount', label: 'Sell Amount', sortable: true },
                { key: 'fxRate', label: 'Fx Rate', sortable: true },
                { key: 'approvalStatus', label: 'Approval Status', sortable: true },
                { key: 'transactionStatus', label: 'Trade Status', sortable: true },                
                { key: 'actions', label: 'Actions' }
            ],
            currency:'USD',
            optCurrency:['USD','SGD'],
            currentPage: 1,
            perPage: 25,
        };
    },
    filters: {
        dateWithTime(value) {
            if (!value) return ''
            var temp = value.toString().split(".");
            value = temp[0].replace("T", " ");
            return value;
        },
        formatNum(value) {
            if (!value) return 0
            const formatter = new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });            
            return formatter.format(value);
            //return value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
        },        
        formatRate(value) {
            if (!value) return 0
            var first = value.toString().substring(0, 1);
            var second = value.toString().substring(1);
            var dec = Math.round(parseInt(second));
            return first + "." + dec.toString();
        },
        setLabel(value){
            if (!value) return ''
            let label = "";
            switch(value) {
            case "1":
                label = "accepted";
                break;
            case "2":
                label = "completed";
                break;
            case "3":
                label = "failed";
                break;
            default:
                label = "reversed";
            }
            return label.toUpperCase();
        }
    },
    created() {
        this.setFxRateParams();
        this.countDownTimer();        
        this.getFxRates(this.params);
        this.getMgAccountBalance();
        this.getActionRole();
        this.getTradeFx();
    },
    methods: {

        getActionRole() {
            var strRoles = localStorage.getItem('roles');
            var roles = strRoles.split(",");
            this.action = roles.includes("ROLE_TRS_TRADE_FX_APPROVER") ? true : false;
        },

        showStatus(tradeNo) {
            this.getStatusTradeFx(tradeNo);
        },

        actionReject(id) {
            var result = confirm("Are you sure want to Reject Trade ?");
            if (result) { this.rejectTradeFx(id); }
        },

        async actionApprove(id, sell_currency, sell_amount) {
            //Get last balance from API 
            this.setFxRateParams(sell_currency);                           
            const resp = await transactService.getMgAccountBalance(this.params);
            if(resp.result === "SUCCESS"){
                var data = resp.balances; 
                for (var key in data) { 
                    var row = data[key];
                    if(sell_currency === row.currency){                    
                        var curr_balance = row.amount;
                        console.log(curr_balance + ' < ' + sell_amount);
                        if (curr_balance < sell_amount) {
                            alert('Failed, '+sell_currency+' Balance should be greater than the Sell Amount!');
                        } else {
                            var result = confirm("Are you sure want to Approve Trade ?");
                            if (result) { this.approveTradeFx(id); }
                        }
                    } 
                }
            } else {
                alert('Failed, Cant find '+curr_balance+' Balance !');
            }
        },

        actionSubmit() {
            this.spinner = true;
            let params = {}; var validate = [];
            params["buyCurrency"]  = "CNY";
            var sellamount = Number(this.sell_amount); ;
            params["sellAmount"]   = sellamount.toFixed(2);
            params["sellCurrency"] = this.currency;

            for (var key in params) {
                if (!params[key]) { validate.push(false); } else { validate.push(true); }
            }

            if (!validate.includes(false)) {
                this.addTradeFx(params);
            } else {
                this.msg = 'Failed, Sell Amount Required!';
                this.color = 'warning'; this.seen = true; this.spinner = false;
                setTimeout(() => this.seen = false, 5000);
            }
        },

        formatNum(value) {
            if (!value) return 0
            const formatter = new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });            
            return formatter.format(value);
            //return value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
        },

        insertChar(main_string, ins_string, pos) {
            if(typeof(pos) == "undefined") {
                pos = 0;
            }
            if(typeof(ins_string) == "undefined") {
                ins_string = '';
            }
            return main_string.slice(0, pos) + ins_string + main_string.slice(pos);
        },

        formatSpecialNum(value){
            if (!value) return 0
            var strValue = value.toString();
            var ttl = strValue.length;
            var pos = ttl-2;            
            var result = this.insertChar(strValue,".",pos);
            return result;
        },

        formatRate(value) {
            if (!value) return 0
            var first = value.toString().substring(0, 1);
            var second = value.toString().substring(1);
            var dec = Math.round(parseInt(second));
            return first + "." + dec.toString();
        },

        loopTimer() {
            this.timeleft = 15;
            this.countDownTimer();
        },

        setFxRateParams(curr){
            let params = {};
            params['buyCurrency'] = "CNY";
            params['sellAmount']  = 1;
            params['sellCurrency']  = (!curr) ? this.currency : curr;
            this.params = params;            
        },

        onChangeCurrency(){
            this.setFxRateParams();
            this.getFxRates(this.params);
            this.getMgAccountBalance();
        },

        countDownTimer() {
            var timeleft = this.timeleft;
            var self_t = this;
            var reloadedTimer = setInterval(function () {
                if (timeleft <= 0) {
                    clearInterval(reloadedTimer);                     
                    document.getElementById("timer").innerHTML = 0;                     
                    self_t.getFxRates(self_t.params);
                    self_t.getMgAccountBalance();
                    timeleft = 15;
                    self_t.loopTimer();
                } else {                    
                    document.getElementById("timer").innerHTML = timeleft;
                }
                timeleft -= 1;
            }, 1500);
        },

        getMgAccountBalance: function () {
            transactService.getMgAccountBalance().then(resp => { //console.log(resp);
                var data = resp.balances; 
                for (var key in data) {
                    var row = data[key];
                    if (row.currency === "CNY") { 
                        this.cny_amount = this.formatSpecialNum(row.amount); 
                    }

                    if(this.currency === row.currency){
                        this.amount  = this.formatSpecialNum(row.amount);                        
                    } 
                }
            }, error => {
                this.spinner = false;
            });
        },

        getFxRates: function (params) {
            transactService.getFxRates(params).then(resp => { //console.log(resp);          
                if (!resp.rate) {
                    console.log(resp.data.message);
                    this.rates = 0;
                    this.err_msg = "* " +resp.data.message;
                } else {
                    this.err_msg = '';
                    this.rates = this.formatRate(resp.rate);
                }
            }, error => {
                this.spinner = false;
            });
        },

        getTradeFx: function () {
            transactService.getTradeFx().then(resp => { //console.log(resp);
                this.items = resp;
            }, error => {
                this.spinner = false;
            });
        },

        addTradeFx: function (params) {
            transactService.addTradeFx(params).then(resp => {
                if (resp.message == 'SUCCESS') {
                    this.msg = 'Add Trade Successfully';
                    this.color = 'success'; this.seen = true; this.sell_amount = 0;
                    this.getTradeFx();
                } else {
                    this.msg = !resp.data.message ? "Add Trade Failed" : resp.data.message;
                    this.color = 'warning';
                }
                this.spinner = false; this.seen = true;
                setTimeout(() => this.seen = false, 5000);
            }, error => {
                this.spinner = false;
            });
        },

        rejectTradeFx: function (id) {
            transactService.rejectTradeFx(id).then(resp => {
                if (resp.message == 'SUCCESS') {
                    alert('Reject Trade Successfully');
                    this.getTradeFx();
                } else {
                    var msg = !resp.data.message ? "Reject Trade Failed" : resp.data.message;
                    alert(msg);
                }
            }, error => {
                this.spinner = false;
            });
        },

        approveTradeFx: function (id) {
            transactService.approveTradeFx(id).then(resp => {
                if (resp.message == 'SUCCESS') {
                    alert('Trade Success Approved');
                    this.getTradeFx();
                } else {
                    var msg = !resp.data.message ? "Approve Trade Failed" : resp.data.message;
                    alert(msg);
                }
            }, error => {
                this.spinner = false;
            });
        },

        getStatusTradeFx: function (tradeNo) {
            transactService.getStatusTradeFx(tradeNo).then(resp => {
                var fxtrade_status = resp.fxtrade_status;
                if (fxtrade_status > 0 && fxtrade_status <= 4) {
                    var status = this.status[resp.fxtrade_status]; 
                    alert('Trade No. ' + tradeNo + ' is ' + status);
                } else {
                    alert('Status Trade No. ' + tradeNo + ' Not Defined !');
                }
            }, error => {
                this.spinner = false;
            });
        },

    }
}
</script>
<style>
.scroll-y {
    height: 60vh;
    overflow-y: scroll;
}

.b-table.sticky-header>thead>tr>th {
    position: sticky !important;
}
</style>